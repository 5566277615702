import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

function Initializing() {
  return (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  );
}

export default Initializing;
