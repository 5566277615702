
var ortWasm = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasm) {
  ortWasm = ortWasm || {};


var d;d||(d=typeof ortWasm !== 'undefined' ? ortWasm : {});var aa,ba;d.ready=new Promise(function(a,b){aa=a;ba=b});var ca=Object.assign({},d),da="./this.program",ea=(a,b)=>{throw b;},fa="object"==typeof window,m="function"==typeof importScripts,p="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,q="",ha,r,v,fs,y,ia;
if(p)q=m?require("path").dirname(q)+"/":__dirname+"/",ia=()=>{y||(fs=require("fs"),y=require("path"))},ha=function(a,b){ia();a=y.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")},v=a=>{a=ha(a,!0);a.buffer||(a=new Uint8Array(a));return a},r=(a,b,c)=>{ia();a=y.normalize(a);fs.readFile(a,function(e,f){e?c(e):b(f.buffer)})},1<process.argv.length&&(da=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("uncaughtException",function(a){if(!(a instanceof ja))throw a;}),process.on("unhandledRejection",
function(a){throw a;}),ea=(a,b)=>{if(noExitRuntime||0<ka)throw process.exitCode=a,b;b instanceof ja||z("exiting due to exception: "+b);process.exit(a)},d.inspect=function(){return"[Emscripten Module object]"};else if(fa||m)m?q=self.location.href:"undefined"!=typeof document&&document.currentScript&&(q=document.currentScript.src),_scriptDir&&(q=_scriptDir),0!==q.indexOf("blob:")?q=q.substr(0,q.replace(/[?#].*/,"").lastIndexOf("/")+1):q="",ha=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);
return b.responseText},m&&(v=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),r=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=c;e.send(null)};var la=d.print||console.log.bind(console),z=d.printErr||console.warn.bind(console);Object.assign(d,ca);ca=null;d.thisProgram&&(da=d.thisProgram);d.quit&&(ea=d.quit);
var A;d.wasmBinary&&(A=d.wasmBinary);var noExitRuntime=d.noExitRuntime||!1;"object"!=typeof WebAssembly&&B("no native wasm support detected");var ma,D=!1,na="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function oa(a,b,c){b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&na)return na.decode(a.subarray(b,c));for(e="";b<c;){var f=a[b++];if(f&128){var h=a[b++]&63;if(192==(f&224))e+=String.fromCharCode((f&31)<<6|h);else{var k=a[b++]&63;f=224==(f&240)?(f&15)<<12|h<<6|k:(f&7)<<18|h<<12|k<<6|a[b++]&63;65536>f?e+=String.fromCharCode(f):(f-=65536,e+=String.fromCharCode(55296|f>>10,56320|f&1023))}}else e+=String.fromCharCode(f)}return e}function pa(a,b){return(a>>>=0)?oa(G,a,b):""}
function qa(a,b,c,e){c>>>=0;if(!(0<e))return 0;var f=c;e=c+e-1;for(var h=0;h<a.length;++h){var k=a.charCodeAt(h);if(55296<=k&&57343>=k){var l=a.charCodeAt(++h);k=65536+((k&1023)<<10)|l&1023}if(127>=k){if(c>=e)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=e)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=e)break;b[c++>>>0]=224|k>>12}else{if(c+3>=e)break;b[c++>>>0]=240|k>>18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-f}
function ra(a){for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b}var sa,H,G,I,J;function ta(){var a=ma.buffer;sa=a;d.HEAP8=H=new Int8Array(a);d.HEAP16=new Int16Array(a);d.HEAP32=I=new Int32Array(a);d.HEAPU8=G=new Uint8Array(a);d.HEAPU16=new Uint16Array(a);d.HEAPU32=J=new Uint32Array(a);d.HEAPF32=new Float32Array(a);d.HEAPF64=new Float64Array(a)}var ua,va=[],wa=[],xa=[],ya=[],ka=0;
function za(){var a=d.preRun.shift();va.unshift(a)}var K=0,Aa=null,L=null;function B(a){if(d.onAbort)d.onAbort(a);a="Aborted("+a+")";z(a);D=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ba(a);throw a;}function Ba(){return N.startsWith("data:application/octet-stream;base64,")}var N;N="ort-wasm.wasm";if(!Ba()){var Ca=N;N=d.locateFile?d.locateFile(Ca,q):q+Ca}
function Da(){var a=N;try{if(a==N&&A)return new Uint8Array(A);if(v)return v(a);throw"both async and sync fetching of the wasm failed";}catch(b){B(b)}}
function Ea(){if(!A&&(fa||m)){if("function"==typeof fetch&&!N.startsWith("file://"))return fetch(N,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+N+"'";return a.arrayBuffer()}).catch(function(){return Da()});if(r)return new Promise(function(a,b){r(N,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Da()})}function ja(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}
function O(a){for(;0<a.length;)a.shift()(d)}var P=[],Q=0,R=0;
function S(a){this.Db=a;this.zb=a-24;this.Ub=function(b){J[this.zb+4>>2>>>0]=b};this.Eb=function(){return J[this.zb+4>>2>>>0]};this.Sb=function(b){J[this.zb+8>>2>>>0]=b};this.Wb=function(){return J[this.zb+8>>2>>>0]};this.Tb=function(){I[this.zb>>2>>>0]=0};this.Ib=function(b){H[this.zb+12>>0>>>0]=b?1:0};this.Pb=function(){return 0!=H[this.zb+12>>0>>>0]};this.Jb=function(b){H[this.zb+13>>0>>>0]=b?1:0};this.Lb=function(){return 0!=H[this.zb+13>>0>>>0]};this.Rb=function(b,c){this.Fb(0);this.Ub(b);this.Sb(c);
this.Tb();this.Ib(!1);this.Jb(!1)};this.Nb=function(){I[this.zb>>2>>>0]+=1};this.Xb=function(){var b=I[this.zb>>2>>>0];I[this.zb>>2>>>0]=b-1;return 1===b};this.Fb=function(b){J[this.zb+16>>2>>>0]=b};this.Ob=function(){return J[this.zb+16>>2>>>0]};this.Qb=function(){if(Fa(this.Eb()))return J[this.Db>>2>>>0];var b=this.Ob();return 0!==b?b:this.Db}}function Ga(a){return Ha((new S(a)).zb)}var T=[];function U(a){var b=T[a];b||(a>=T.length&&(T.length=a+1),T[a]=b=ua.get(a));return b}
function Ia(a){var b=ra(a)+1,c=Ja(b);c&&qa(a,H,c,b);return c}function Ka(a,b,c){function e(n){return(n=n.toTimeString().match(/\(([A-Za-z ]+)\)$/))?n[1]:"GMT"}var f=(new Date).getFullYear(),h=new Date(f,0,1),k=new Date(f,6,1);f=h.getTimezoneOffset();var l=k.getTimezoneOffset();I[a>>2>>>0]=60*Math.max(f,l);I[b>>2>>>0]=Number(f!=l);a=e(h);b=e(k);a=Ia(a);b=Ia(b);l<f?(J[c>>2>>>0]=a,J[c+4>>2>>>0]=b):(J[c>>2>>>0]=b,J[c+4>>2>>>0]=a)}function La(a,b,c){La.Vb||(La.Vb=!0,Ka(a,b,c))}var Ma={};
function Na(){if(!Oa){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:da||"./this.program"},b;for(b in Ma)void 0===Ma[b]?delete a[b]:a[b]=Ma[b];var c=[];for(b in a)c.push(b+"="+a[b]);Oa=c}return Oa}var Oa,Pa=[null,[],[]];function Qa(a,b){var c=Pa[a];0===b||10===b?((1===a?la:z)(oa(c,0)),c.length=0):c.push(b)}var V=0;
function Ra(){if("object"==typeof crypto&&"function"==typeof crypto.getRandomValues){var a=new Uint8Array(1);return()=>{crypto.getRandomValues(a);return a[0]}}if(p)try{var b=require("crypto");return()=>b.randomBytes(1)[0]}catch(c){}return()=>B("randomDevice")}function W(a,b){W.Mb||(W.Mb=Ra());for(var c=0;c<b;c++)H[a+c>>0>>>0]=W.Mb();return 0}function Sa(a){return 0===a%4&&(0!==a%100||0===a%400)}var Ta=[31,29,31,30,31,30,31,31,30,31,30,31],Ua=[31,28,31,30,31,30,31,31,30,31,30,31];
function Va(a){var b=Array(ra(a)+1);qa(a,b,0,b.length);return b}
function Wa(a,b,c,e){function f(g,u,w){for(g="number"==typeof g?g.toString():g||"";g.length<u;)g=w[0]+g;return g}function h(g,u){return f(g,u,"0")}function k(g,u){function w(M){return 0>M?-1:0<M?1:0}var F;0===(F=w(g.getFullYear()-u.getFullYear()))&&0===(F=w(g.getMonth()-u.getMonth()))&&(F=w(g.getDate()-u.getDate()));return F}function l(g){switch(g.getDay()){case 0:return new Date(g.getFullYear()-1,11,29);case 1:return g;case 2:return new Date(g.getFullYear(),0,3);case 3:return new Date(g.getFullYear(),
0,2);case 4:return new Date(g.getFullYear(),0,1);case 5:return new Date(g.getFullYear()-1,11,31);case 6:return new Date(g.getFullYear()-1,11,30)}}function n(g){var u=g.Bb;for(g=new Date((new Date(g.Cb+1900,0,1)).getTime());0<u;){var w=g.getMonth(),F=(Sa(g.getFullYear())?Ta:Ua)[w];if(u>F-g.getDate())u-=F-g.getDate()+1,g.setDate(1),11>w?g.setMonth(w+1):(g.setMonth(0),g.setFullYear(g.getFullYear()+1));else{g.setDate(g.getDate()+u);break}}w=new Date(g.getFullYear()+1,0,4);u=l(new Date(g.getFullYear(),
0,4));w=l(w);return 0>=k(u,g)?0>=k(w,g)?g.getFullYear()+1:g.getFullYear():g.getFullYear()-1}var t=I[e+40>>2>>>0];e={$b:I[e>>2>>>0],Zb:I[e+4>>2>>>0],Gb:I[e+8>>2>>>0],Kb:I[e+12>>2>>>0],Hb:I[e+16>>2>>>0],Cb:I[e+20>>2>>>0],Ab:I[e+24>>2>>>0],Bb:I[e+28>>2>>>0],bc:I[e+32>>2>>>0],Yb:I[e+36>>2>>>0],ac:t?pa(t):""};c=pa(c);t={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y",
"%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var x in t)c=c.replace(new RegExp(x,"g"),t[x]);var E="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),C="January February March April May June July August September October November December".split(" ");t={"%a":function(g){return E[g.Ab].substring(0,3)},"%A":function(g){return E[g.Ab]},"%b":function(g){return C[g.Hb].substring(0,
3)},"%B":function(g){return C[g.Hb]},"%C":function(g){return h((g.Cb+1900)/100|0,2)},"%d":function(g){return h(g.Kb,2)},"%e":function(g){return f(g.Kb,2," ")},"%g":function(g){return n(g).toString().substring(2)},"%G":function(g){return n(g)},"%H":function(g){return h(g.Gb,2)},"%I":function(g){g=g.Gb;0==g?g=12:12<g&&(g-=12);return h(g,2)},"%j":function(g){for(var u=0,w=0;w<=g.Hb-1;u+=(Sa(g.Cb+1900)?Ta:Ua)[w++]);return h(g.Kb+u,3)},"%m":function(g){return h(g.Hb+1,2)},"%M":function(g){return h(g.Zb,
2)},"%n":function(){return"\n"},"%p":function(g){return 0<=g.Gb&&12>g.Gb?"AM":"PM"},"%S":function(g){return h(g.$b,2)},"%t":function(){return"\t"},"%u":function(g){return g.Ab||7},"%U":function(g){return h(Math.floor((g.Bb+7-g.Ab)/7),2)},"%V":function(g){var u=Math.floor((g.Bb+7-(g.Ab+6)%7)/7);2>=(g.Ab+371-g.Bb-2)%7&&u++;if(u)53==u&&(w=(g.Ab+371-g.Bb)%7,4==w||3==w&&Sa(g.Cb)||(u=1));else{u=52;var w=(g.Ab+7-g.Bb-1)%7;(4==w||5==w&&Sa(g.Cb%400-1))&&u++}return h(u,2)},"%w":function(g){return g.Ab},"%W":function(g){return h(Math.floor((g.Bb+
7-(g.Ab+6)%7)/7),2)},"%y":function(g){return(g.Cb+1900).toString().substring(2)},"%Y":function(g){return g.Cb+1900},"%z":function(g){g=g.Yb;var u=0<=g;g=Math.abs(g)/60;return(u?"+":"-")+String("0000"+(g/60*100+g%60)).slice(-4)},"%Z":function(g){return g.ac},"%%":function(){return"%"}};c=c.replace(/%%/g,"\x00\x00");for(x in t)c.includes(x)&&(c=c.replace(new RegExp(x,"g"),t[x](e)));c=c.replace(/\0\0/g,"%");x=Va(c);if(x.length>b)return 0;H.set(x,a>>>0);return x.length-1}
var Jb={a:function(a){return Ja(a+24)+24},m:function(a){a=new S(a);a.Pb()||(a.Ib(!0),Q--);a.Jb(!1);P.push(a);a.Nb();return a.Qb()},ia:function(a){z("Unexpected exception thrown, this is not properly supported - aborting");D=!0;throw a;},w:function(){X(0);var a=P.pop();if(a.Xb()&&!a.Lb()){var b=a.Wb();b&&U(b)(a.Db);Ga(a.Db)}R=0},d:function(){var a=R;if(!a)return V=0;var b=new S(a);b.Fb(a);var c=b.Eb();if(!c)return V=0,a;for(var e=Array.prototype.slice.call(arguments),f=0;f<e.length;f++){var h=e[f];
if(0===h||h===c)break;if(Xa(h,c,b.zb+16))return V=h,a}V=c;return a},k:function(){var a=R;if(!a)return V=0;var b=new S(a);b.Fb(a);var c=b.Eb();if(!c)return V=0,a;for(var e=Array.prototype.slice.call(arguments),f=0;f<e.length;f++){var h=e[f];if(0===h||h===c)break;if(Xa(h,c,b.zb+16))return V=h,a}V=c;return a},g:function(){var a=R;if(!a)return V=0;var b=new S(a);b.Fb(a);var c=b.Eb();if(!c)return V=0,a;for(var e=Array.prototype.slice.call(arguments),f=0;f<e.length;f++){var h=e[f];if(0===h||h===c)break;
if(Xa(h,c,b.zb+16))return V=h,a}V=c;return a},s:Ga,L:function(){var a=P.pop();a||B("no exception to throw");var b=a.Db;a.Lb()||(P.push(a),a.Jb(!0),a.Ib(!1),Q++);R=b;throw b;},b:function(a,b,c){(new S(a)).Rb(b,c);R=a;Q++;throw a;},la:function(){return Q},i:function(a){R||(R=a);throw a;},H:function(){return 0},Ba:function(){},pa:function(){},ra:function(){},ka:function(){return 0},za:function(){},ua:function(){},ya:function(){},R:function(){},qa:function(){},na:function(){},Aa:function(){},oa:function(){},
Ha:function(){},Ja:function(){B("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},Ia:function(){B("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},S:function(){return Date.now()},Ca:function(){return!0},Da:function(a,b){a=new Date(1E3*(J[a>>>2]+4294967296*I[a+4>>>2]));I[b>>2>>>0]=a.getUTCSeconds();I[b+4>>2>>>0]=a.getUTCMinutes();I[b+8>>2>>>0]=a.getUTCHours();I[b+12>>2>>>
0]=a.getUTCDate();I[b+16>>2>>>0]=a.getUTCMonth();I[b+20>>2>>>0]=a.getUTCFullYear()-1900;I[b+24>>2>>>0]=a.getUTCDay();I[b+28>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},Ea:function(a,b){a=new Date(1E3*(J[a>>>2]+4294967296*I[a+4>>>2]));I[b>>2>>>0]=a.getSeconds();I[b+4>>2>>>0]=a.getMinutes();I[b+8>>2>>>0]=a.getHours();I[b+12>>2>>>0]=a.getDate();I[b+16>>2>>>0]=a.getMonth();I[b+20>>2>>>0]=a.getFullYear()-1900;I[b+24>>2>>>0]=a.getDay();var c=new Date(a.getFullYear(),0,1);I[b+
28>>2>>>0]=(a.getTime()-c.getTime())/864E5|0;I[b+36>>2>>>0]=-(60*a.getTimezoneOffset());var e=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();c=c.getTimezoneOffset();I[b+32>>2>>>0]=(e!=c&&a.getTimezoneOffset()==Math.min(c,e))|0},Fa:function(a){var b=new Date(I[a+20>>2>>>0]+1900,I[a+16>>2>>>0],I[a+12>>2>>>0],I[a+8>>2>>>0],I[a+4>>2>>>0],I[a>>2>>>0],0),c=I[a+32>>2>>>0],e=b.getTimezoneOffset(),f=new Date(b.getFullYear(),0,1),h=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),k=f.getTimezoneOffset(),
l=Math.min(k,h);0>c?I[a+32>>2>>>0]=Number(h!=k&&l==e):0<c!=(l==e)&&(h=Math.max(k,h),b.setTime(b.getTime()+6E4*((0<c?l:h)-e)));I[a+24>>2>>>0]=b.getDay();I[a+28>>2>>>0]=(b.getTime()-f.getTime())/864E5|0;I[a>>2>>>0]=b.getSeconds();I[a+4>>2>>>0]=b.getMinutes();I[a+8>>2>>>0]=b.getHours();I[a+12>>2>>>0]=b.getDate();I[a+16>>2>>>0]=b.getMonth();return b.getTime()/1E3|0},sa:function(){return-52},ta:function(){},Ga:La,B:function(){B("")},ma:function(){return 4294901760},I:p?()=>{var a=process.hrtime();return 1E3*
a[0]+a[1]/1E6}:()=>performance.now(),xa:function(a,b,c){G.copyWithin(a>>>0,b>>>0,b+c>>>0)},G:function(a){var b=G.length;a>>>=0;if(4294901760<a)return!1;for(var c=1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var f=Math;e=Math.max(a,e);f=f.min.call(f,4294901760,e+(65536-e%65536)%65536);a:{try{ma.grow(f-sa.byteLength+65535>>>16);ta();var h=1;break a}catch(k){}h=void 0}if(h)return!0}return!1},va:function(a,b){var c=0;Na().forEach(function(e,f){var h=b+c;f=J[a+4*f>>2>>>0]=h;for(h=0;h<e.length;++h)H[f++>>
0>>>0]=e.charCodeAt(h);H[f>>0>>>0]=0;c+=e.length+1});return 0},wa:function(a,b){var c=Na();J[a>>2>>>0]=c.length;var e=0;c.forEach(function(f){e+=f.length+1});J[b>>2>>>0]=e;return 0},ba:function(a){noExitRuntime||0<ka||(Ya(),O(xa),Za(0),Pa[1].length&&Qa(1,10),Pa[2].length&&Qa(2,10));if(!(noExitRuntime||0<ka)){if(d.onExit)d.onExit(a);D=!0}ea(a,new ja(a))},E:function(){return 52},Q:function(){return 52},ca:function(){return 70},P:function(a,b,c,e){for(var f=0,h=0;h<c;h++){var k=J[b>>2>>>0],l=J[b+4>>
2>>>0];b+=8;for(var n=0;n<l;n++)Qa(a,G[k+n>>>0]);f+=l}J[e>>2>>>0]=f;return 0},c:function(){return V},ja:W,ea:$a,fa:ab,J:bb,e:cb,N:db,O:eb,j:fb,o:gb,p:hb,M:ib,r:jb,v:kb,K:lb,D:mb,X:nb,V:ob,U:pb,Z:qb,W:rb,Y:sb,T:tb,f:ub,q:vb,h:wb,da:xb,l:yb,t:zb,u:Ab,x:Bb,z:Cb,ga:Db,A:Eb,C:Fb,aa:Gb,_:Hb,$:Ib,n:function(a){return a},F:function(a){V=a},ha:Wa,y:function(a,b,c,e){return Wa(a,b,c,e)}};
(function(){function a(f){d.asm=f.exports;ma=d.asm.Ka;ta();ua=d.asm.ib;wa.unshift(d.asm.La);K--;d.monitorRunDependencies&&d.monitorRunDependencies(K);0==K&&(null!==Aa&&(clearInterval(Aa),Aa=null),L&&(f=L,L=null,f()))}function b(f){a(f.instance)}function c(f){return Ea().then(function(h){return WebAssembly.instantiate(h,e)}).then(function(h){return h}).then(f,function(h){z("failed to asynchronously prepare wasm: "+h);B(h)})}var e={a:Jb};K++;d.monitorRunDependencies&&d.monitorRunDependencies(K);if(d.instantiateWasm)try{return d.instantiateWasm(e,
a)}catch(f){return z("Module.instantiateWasm callback failed with error: "+f),!1}(function(){return A||"function"!=typeof WebAssembly.instantiateStreaming||Ba()||N.startsWith("file://")||p||"function"!=typeof fetch?c(b):fetch(N,{credentials:"same-origin"}).then(function(f){return WebAssembly.instantiateStreaming(f,e).then(b,function(h){z("wasm streaming compile failed: "+h);z("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ba);return{}})();
d.___wasm_call_ctors=function(){return(d.___wasm_call_ctors=d.asm.La).apply(null,arguments)};d._OrtInit=function(){return(d._OrtInit=d.asm.Ma).apply(null,arguments)};d._OrtCreateSessionOptions=function(){return(d._OrtCreateSessionOptions=d.asm.Na).apply(null,arguments)};d._OrtAppendExecutionProvider=function(){return(d._OrtAppendExecutionProvider=d.asm.Oa).apply(null,arguments)};d._OrtAddSessionConfigEntry=function(){return(d._OrtAddSessionConfigEntry=d.asm.Pa).apply(null,arguments)};
d._OrtReleaseSessionOptions=function(){return(d._OrtReleaseSessionOptions=d.asm.Qa).apply(null,arguments)};d._OrtCreateSession=function(){return(d._OrtCreateSession=d.asm.Ra).apply(null,arguments)};d._OrtReleaseSession=function(){return(d._OrtReleaseSession=d.asm.Sa).apply(null,arguments)};d._OrtGetInputCount=function(){return(d._OrtGetInputCount=d.asm.Ta).apply(null,arguments)};d._OrtGetOutputCount=function(){return(d._OrtGetOutputCount=d.asm.Ua).apply(null,arguments)};
d._OrtGetInputName=function(){return(d._OrtGetInputName=d.asm.Va).apply(null,arguments)};d._OrtGetOutputName=function(){return(d._OrtGetOutputName=d.asm.Wa).apply(null,arguments)};d._OrtFree=function(){return(d._OrtFree=d.asm.Xa).apply(null,arguments)};d._OrtCreateTensor=function(){return(d._OrtCreateTensor=d.asm.Ya).apply(null,arguments)};d._OrtGetTensorData=function(){return(d._OrtGetTensorData=d.asm.Za).apply(null,arguments)};
d._OrtReleaseTensor=function(){return(d._OrtReleaseTensor=d.asm._a).apply(null,arguments)};d._OrtCreateRunOptions=function(){return(d._OrtCreateRunOptions=d.asm.$a).apply(null,arguments)};d._OrtAddRunConfigEntry=function(){return(d._OrtAddRunConfigEntry=d.asm.ab).apply(null,arguments)};d._OrtReleaseRunOptions=function(){return(d._OrtReleaseRunOptions=d.asm.bb).apply(null,arguments)};d._OrtRun=function(){return(d._OrtRun=d.asm.cb).apply(null,arguments)};
d._OrtEndProfiling=function(){return(d._OrtEndProfiling=d.asm.db).apply(null,arguments)};
var Ja=d._malloc=function(){return(Ja=d._malloc=d.asm.eb).apply(null,arguments)},Ha=d._free=function(){return(Ha=d._free=d.asm.fb).apply(null,arguments)},Za=d._fflush=function(){return(Za=d._fflush=d.asm.gb).apply(null,arguments)},Ya=d.___funcs_on_exit=function(){return(Ya=d.___funcs_on_exit=d.asm.hb).apply(null,arguments)},X=d._setThrew=function(){return(X=d._setThrew=d.asm.jb).apply(null,arguments)},Y=d.stackSave=function(){return(Y=d.stackSave=d.asm.kb).apply(null,arguments)},Z=d.stackRestore=
function(){return(Z=d.stackRestore=d.asm.lb).apply(null,arguments)},Kb=d.stackAlloc=function(){return(Kb=d.stackAlloc=d.asm.mb).apply(null,arguments)},Xa=d.___cxa_can_catch=function(){return(Xa=d.___cxa_can_catch=d.asm.nb).apply(null,arguments)},Fa=d.___cxa_is_pointer_type=function(){return(Fa=d.___cxa_is_pointer_type=d.asm.ob).apply(null,arguments)},Lb=d.dynCall_j=function(){return(Lb=d.dynCall_j=d.asm.pb).apply(null,arguments)},Mb=d.dynCall_iiiiij=function(){return(Mb=d.dynCall_iiiiij=d.asm.qb).apply(null,
arguments)},Nb=d.dynCall_jii=function(){return(Nb=d.dynCall_jii=d.asm.rb).apply(null,arguments)},Ob=d.dynCall_viiiiij=function(){return(Ob=d.dynCall_viiiiij=d.asm.sb).apply(null,arguments)},Pb=d.dynCall_vjji=function(){return(Pb=d.dynCall_vjji=d.asm.tb).apply(null,arguments)},Qb=d.dynCall_viiijjjii=function(){return(Qb=d.dynCall_viiijjjii=d.asm.ub).apply(null,arguments)},Rb=d.dynCall_iij=function(){return(Rb=d.dynCall_iij=d.asm.vb).apply(null,arguments)},Sb=d.dynCall_ji=function(){return(Sb=d.dynCall_ji=
d.asm.wb).apply(null,arguments)},Tb=d.dynCall_iiiiiij=function(){return(Tb=d.dynCall_iiiiiij=d.asm.xb).apply(null,arguments)},Ub=d.dynCall_iiij=function(){return(Ub=d.dynCall_iiij=d.asm.yb).apply(null,arguments)};function cb(a,b){var c=Y();try{return U(a)(b)}catch(e){Z(c);if(e!==e+0)throw e;X(1,0)}}function vb(a,b){var c=Y();try{U(a)(b)}catch(e){Z(c);if(e!==e+0)throw e;X(1,0)}}function wb(a,b,c){var e=Y();try{U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}
function fb(a,b,c){var e=Y();try{return U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}function gb(a,b,c,e){var f=Y();try{return U(a)(b,c,e)}catch(h){Z(f);if(h!==h+0)throw h;X(1,0)}}function kb(a,b,c,e,f,h,k){var l=Y();try{return U(a)(b,c,e,f,h,k)}catch(n){Z(l);if(n!==n+0)throw n;X(1,0)}}function ub(a){var b=Y();try{U(a)()}catch(c){Z(b);if(c!==c+0)throw c;X(1,0)}}function jb(a,b,c,e,f,h){var k=Y();try{return U(a)(b,c,e,f,h)}catch(l){Z(k);if(l!==l+0)throw l;X(1,0)}}
function hb(a,b,c,e,f){var h=Y();try{return U(a)(b,c,e,f)}catch(k){Z(h);if(k!==k+0)throw k;X(1,0)}}function yb(a,b,c,e){var f=Y();try{U(a)(b,c,e)}catch(h){Z(f);if(h!==h+0)throw h;X(1,0)}}function Ab(a,b,c,e,f,h){var k=Y();try{U(a)(b,c,e,f,h)}catch(l){Z(k);if(l!==l+0)throw l;X(1,0)}}function zb(a,b,c,e,f){var h=Y();try{U(a)(b,c,e,f)}catch(k){Z(h);if(k!==k+0)throw k;X(1,0)}}function Bb(a,b,c,e,f,h,k){var l=Y();try{U(a)(b,c,e,f,h,k)}catch(n){Z(l);if(n!==n+0)throw n;X(1,0)}}
function Cb(a,b,c,e,f,h,k,l){var n=Y();try{U(a)(b,c,e,f,h,k,l)}catch(t){Z(n);if(t!==t+0)throw t;X(1,0)}}function eb(a,b,c){var e=Y();try{return U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}function db(a,b,c){var e=Y();try{return U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}function Db(a,b,c,e,f,h,k,l,n){var t=Y();try{U(a)(b,c,e,f,h,k,l,n)}catch(x){Z(t);if(x!==x+0)throw x;X(1,0)}}function ib(a,b,c,e,f,h){var k=Y();try{return U(a)(b,c,e,f,h)}catch(l){Z(k);if(l!==l+0)throw l;X(1,0)}}
function lb(a,b,c,e,f,h,k,l){var n=Y();try{return U(a)(b,c,e,f,h,k,l)}catch(t){Z(n);if(t!==t+0)throw t;X(1,0)}}function mb(a,b,c,e,f,h,k,l,n,t,x,E){var C=Y();try{return U(a)(b,c,e,f,h,k,l,n,t,x,E)}catch(g){Z(C);if(g!==g+0)throw g;X(1,0)}}function Eb(a,b,c,e,f,h,k,l,n,t,x){var E=Y();try{U(a)(b,c,e,f,h,k,l,n,t,x)}catch(C){Z(E);if(C!==C+0)throw C;X(1,0)}}function Fb(a,b,c,e,f,h,k,l,n,t,x,E,C,g,u,w){var F=Y();try{U(a)(b,c,e,f,h,k,l,n,t,x,E,C,g,u,w)}catch(M){Z(F);if(M!==M+0)throw M;X(1,0)}}
function bb(a){var b=Y();try{return U(a)()}catch(c){Z(b);if(c!==c+0)throw c;X(1,0)}}function ab(a,b,c){var e=Y();try{return U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}function $a(a,b,c){var e=Y();try{return U(a)(b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}function xb(a,b,c,e){var f=Y();try{U(a)(b,c,e)}catch(h){Z(f);if(h!==h+0)throw h;X(1,0)}}function Gb(a,b,c,e,f,h,k,l){var n=Y();try{Ob(a,b,c,e,f,h,k,l)}catch(t){Z(n);if(t!==t+0)throw t;X(1,0)}}
function Ib(a,b,c,e,f,h){var k=Y();try{Pb(a,b,c,e,f,h)}catch(l){Z(k);if(l!==l+0)throw l;X(1,0)}}function Hb(a,b,c,e,f,h,k,l,n,t,x,E){var C=Y();try{Qb(a,b,c,e,f,h,k,l,n,t,x,E)}catch(g){Z(C);if(g!==g+0)throw g;X(1,0)}}function qb(a,b,c,e){var f=Y();try{return Rb(a,b,c,e)}catch(h){Z(f);if(h!==h+0)throw h;X(1,0)}}function sb(a,b){var c=Y();try{return Sb(a,b)}catch(e){Z(c);if(e!==e+0)throw e;X(1,0)}}
function nb(a,b,c,e,f,h,k,l){var n=Y();try{return Tb(a,b,c,e,f,h,k,l)}catch(t){Z(n);if(t!==t+0)throw t;X(1,0)}}function rb(a){var b=Y();try{return Lb(a)}catch(c){Z(b);if(c!==c+0)throw c;X(1,0)}}function ob(a,b,c,e,f,h,k){var l=Y();try{return Mb(a,b,c,e,f,h,k)}catch(n){Z(l);if(n!==n+0)throw n;X(1,0)}}function pb(a,b,c,e,f){var h=Y();try{return Ub(a,b,c,e,f)}catch(k){Z(h);if(k!==k+0)throw k;X(1,0)}}function tb(a,b,c){var e=Y();try{return Nb(a,b,c)}catch(f){Z(e);if(f!==f+0)throw f;X(1,0)}}
d.UTF8ToString=pa;d.stringToUTF8=function(a,b,c){return qa(a,G,b,c)};d.lengthBytesUTF8=ra;d.stackSave=Y;d.stackRestore=Z;d.stackAlloc=Kb;var Vb;L=function Wb(){Vb||Xb();Vb||(L=Wb)};
function Xb(){function a(){if(!Vb&&(Vb=!0,d.calledRun=!0,!D)){O(wa);aa(d);if(d.onRuntimeInitialized)d.onRuntimeInitialized();if(d.postRun)for("function"==typeof d.postRun&&(d.postRun=[d.postRun]);d.postRun.length;){var b=d.postRun.shift();ya.unshift(b)}O(ya)}}if(!(0<K)){if(d.preRun)for("function"==typeof d.preRun&&(d.preRun=[d.preRun]);d.preRun.length;)za();O(va);0<K||(d.setStatus?(d.setStatus("Running..."),setTimeout(function(){setTimeout(function(){d.setStatus("")},1);a()},1)):a())}}
if(d.preInit)for("function"==typeof d.preInit&&(d.preInit=[d.preInit]);0<d.preInit.length;)d.preInit.pop()();Xb();


  return ortWasm.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasm; });
else if (typeof exports === 'object')
  exports["ortWasm"] = ortWasm;
