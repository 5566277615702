import React from 'react';
import { useTheme, Typography, Paper, Box, Alert } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function ChatLine({ message }) {
  const isAssistant = message.role === 'assistant';

  return (
    <Box
      sx={{
        // display: 'flex',
        justifyContent: isAssistant ? 'flex-end' : 'flex-start',
        mb: 2,
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          p: 2,
          // backgroundColor: isBot ? "primary.light" : "secondary.light",
          borderRadius: isAssistant
            ? '20px 20px 5px 20px'
            : '20px 20px 20px 5px',
          justifyContent: isAssistant ? 'flex-end' : 'flex-start',
        }}
      >
        <Grid container>
          <Grid lg={12} md={12} xs={12} align={isAssistant ? 'right' : 'left'}>
            <Typography variant='overline'>{message.role}</Typography>
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {message.content.trim()}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

function ChatDialog({ chat_history, generation }) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        margin: '10px',
        backgroundColor: theme.palette.grey[100],
        minHeight: '300px',
      }}
    >
      {chat_history.length ? (
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          {chat_history.map((message, i) => (
            <ChatLine key={`message_${i}`} message={message} />
          ))}
          {generation ? (
            <ChatLine
              key={`message_generating`}
              message={{ role: 'assistant', content: generation }}
            />
          ) : undefined}
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            p: 2,
            borderRadius: '20px 20px 20px 20px',
          }}
        >
          <Alert severity='info'>Ask a question to get started</Alert>
        </Box>
      )}
    </Paper>
  );
}

export default ChatDialog;
