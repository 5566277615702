import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';

import round from '../../../../util/round';

function SourceDocument({ doc }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography
        component={Link}
        onClick={() => {
          setOpen(true);
        }}
        variant='caption'
        sx={{ padding: '1px' }}
      >
        {round(doc._additional.certainty * 100, 2)}% - {doc.title} - page{' '}
        {doc.page_number}
      </Typography>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth={800}>
        <DialogTitle>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs>
              <b>
                {doc.title} - page {doc.page_number}
              </b>
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box
            padding='10px'
            sx={{
              maxWidth: 800,
            }}
          >
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>Document Snippet</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                  {doc.zContent}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default SourceDocument;
