import { createContext, useContext, useReducer } from 'react';
import {
  CLEAR_AUTH_KEY,
  SET_AUTH_KEY,
  SET_GENERATION_CONFIG,
  SET_VDB_KEY,
} from './actionTypes';

const ConfigurationContext = createContext(null);

const ConfigurationDispatchContext = createContext(null);

const initialState = {
  auth_key: localStorage.getItem('auth_key'),
  endpoint: {
    llm_endpoint: process.env.REACT_APP_LLM_ENDPOINT,
    vdb_endpoint: process.env.REACT_APP_VDB_ENDPOINT,
    vdb_key: null,
  },
  generation: {
    max_new_tokens:
      parseInt(localStorage.getItem('generation_max_new_tokens')) || 512,
    temperature:
      parseFloat(localStorage.getItem('generation_temperature')) || 0.8,
    top_p: parseFloat(localStorage.getItem('generation_top_p')) || 0.95,
    num_docs: parseInt(localStorage.getItem('generation_num_docs')) || 5,
    typical_p: parseFloat(localStorage.getItem('generation_typical_p')) || 0.95,
  },
  model: {
    max_input_length: 3072,
    prompt_template_tokens: 105,
    chat_pair_tokens: 34,
    tokenizer: 'NousResearch/Llama-2-7b-hf',
  },
};

export function ConfigurationProvider({ children }) {
  const [configuration, dispatch] = useReducer(
    configurationReducer,
    initialState
  );

  return (
    <ConfigurationContext.Provider value={configuration}>
      <ConfigurationDispatchContext.Provider value={dispatch}>
        {children}
      </ConfigurationDispatchContext.Provider>
    </ConfigurationContext.Provider>
  );
}

export function useConfiguration() {
  return useContext(ConfigurationContext);
}

export function useConfigurationDispatch() {
  return useContext(ConfigurationDispatchContext);
}

function configurationReducer(configuration, action) {
  switch (action.type) {
    case SET_VDB_KEY: {
      return {
        ...configuration,
        endpoint: { ...configuration.endpoint, vdb_key: action.payload },
      };
    }
    case SET_AUTH_KEY: {
      localStorage.setItem('auth_key', action.payload);
      return {
        ...configuration,
        auth_key: action.payload,
      };
    }
    case CLEAR_AUTH_KEY: {
      localStorage.removeItem('auth_key');
      return {
        ...configuration,
        auth_key: null,
      };
    }
    case SET_GENERATION_CONFIG: {
      Object.entries(action.payload).forEach(([k, v]) => {
        if (v !== undefined) {
          localStorage.setItem(`generation_${k}`, v);
        } else {
          localStorage.removeItem(`generation_${k}`);
        }
      });
      return {
        ...configuration,
        generation: { ...action.payload },
      };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
