import React, { useState } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Slider,
  Typography,
} from '@mui/material';

export default function TemperatureField({ value, setValue, ...rest }) {
  const [initialValue] = useState(value);
  const handleChange = (event, newValue) => {
    setValue(parseFloat(newValue));
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <Typography>Temperature</Typography>
        <Slider
          {...rest}
          aria-label='Temperature'
          defaultValue={initialValue}
          valueLabelDisplay='auto'
          aria-describedby='temperature-helper-text'
          onChange={handleChange}
          step={0.01}
          min={0}
          max={2}
        />

        <FormHelperText id='temperature-helper-text'>
          The value used to modulate the next token probabilities.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
