import { createContext, useContext, useReducer } from 'react';
import { USER_FETCH, USER_LOGOUT } from './actionTypes';

const UserContext = createContext(null);

const UserDispatchContext = createContext(null);

const initialState = {};

export function UserProvider({ children }) {
  const [user, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

export function useUserDispatch() {
  return useContext(UserDispatchContext);
}

function userReducer(user, action) {
  switch (action.type) {
    case USER_LOGOUT: {
      return initialState;
    }
    case USER_FETCH: {
      return {
        ...action.payload,
      };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
