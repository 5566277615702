import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import {
  useConfiguration,
  useConfigurationDispatch,
} from '../../providers/ConfigurationContext';
import { getLength } from '../../util/tokenizer';
import {
  CLEAR_AUTH_KEY,
  SET_VDB_KEY,
  USER_FETCH,
  USER_LOGOUT,
} from '../../providers/actionTypes';
import PasswordDialog from './PasswordDialog';
import api from '../../apis/api';
import { useUserDispatch } from '../../providers/UserContext';
import { getUser } from '../../actions/user';
import Chat from './Chat';

function Landing() {
  const configuration = useConfiguration();
  const configurationDispatch = useConfigurationDispatch();
  const userDispatch = useUserDispatch();

  const [passwordOpen, setPasswordOpen] = useState(!configuration?.auth_key);
  const [password_error, setPasswordError] = useState(null);

  useEffect(() => {
    // warmup tokenizer
    if (configuration?.model?.tokenizer) {
      getLength(configuration?.model?.tokenizer, 'warmup');
    }
  }, [configuration?.model?.tokenizer]);

  useEffect(() => {
    // get vdb key
    if (configuration?.auth_key) {
      api
        .post('/auth', {
          password: configuration.auth_key,
        })
        .then((resp) => {
          console.log(resp);
          localStorage.setItem('accqsure_jwt', JSON.parse(resp.data.token).sub);
          configurationDispatch({
            type: SET_VDB_KEY,
            payload: JSON.parse(resp.data.token).vdb_key,
          });
        })
        .catch((error) => {
          console.log(error);
          setPasswordError('Invalid password');
          setPasswordOpen(true);
          configurationDispatch({ type: CLEAR_AUTH_KEY });
          userDispatch({ type: USER_LOGOUT });
        });
    }
  }, [configuration?.auth_key, configurationDispatch]);

  useEffect(() => {
    if (localStorage.getItem('accqsure_jwt')) {
      getUser()
        .then((user) => {
          console.log(user);
          userDispatch({ type: USER_FETCH, payload: user });
        })
        .catch((error) => {
          console.log(error);
          setPasswordError('Login Error');
          setPasswordOpen(true);
          configurationDispatch({ type: CLEAR_AUTH_KEY });
          userDispatch({ type: USER_LOGOUT });
        });
    }
  }, [localStorage.getItem('accqsure_jwt'), userDispatch]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Chat />

      <PasswordDialog
        open={passwordOpen}
        handleClose={() => {
          setPasswordError(null);
          setPasswordOpen(false);
        }}
        error={password_error}
      />
    </Box>
  );
}

export default Landing;
