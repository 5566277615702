import api from '../apis/api';

export const getChats = async () => {
  const resp = await api.get('/chat');
  return resp.data;
};

export const getChat = async (chat_id) => {
  const resp = await api.get(`/chat/${chat_id}`);
  return resp.data;
};

export const createChat = async (values) => {
  const resp = await api.post(`/chat/`, values);
  return resp.data;
};

export const updateChat = async (chat_id, values) => {
  const resp = await api.put(`/chat/${chat_id}`, values);
  return resp.data;
};

export const deleteChat = async (chat_id) => {
  const resp = await api.delete(`/chat/${chat_id}`);
  return resp.data;
};

export const getChatItems = async (chat_id) => {
  const resp = await api.get(`/chat/${chat_id}/item`);
  return resp.data;
};

export const addChatItem = async (chat_id, values) => {
  const resp = await api.post(`/chat/${chat_id}/item`, values);
  return resp.data;
};
