import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Initializing from './Initializing';
import Layout from './Layout';
import Landing from './Landing';

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Landing />,
          children: [],
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_startTransition: true,
    },
  }
);

function App() {
  return <RouterProvider router={router} fallbackElement={<Initializing />} />;
}

export default App;
