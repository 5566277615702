import weaviate from 'weaviate-ts-client';

const searchDocs = async (endpoint, auth_key, prompt, num_docs) => {
  const client = weaviate.client({
    scheme: 'https',
    host: endpoint,
    apiKey: new weaviate.ApiKey(auth_key),
  });

  const response = await client.graphql
    .get()
    .withClassName('Document')
    .withFields(
      'zContent type title page_number _additional{certainty distance}'
    )
    .withNearText({
      concepts: [`query: ${prompt}`],
    })
    .withLimit(num_docs)
    .do();

  return response.data.Get['Document'];
};
export default searchDocs;
