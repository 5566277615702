import React, { useState } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Slider,
  Typography,
} from '@mui/material';

export default function TypicalPField({ value, setValue, ...rest }) {
  const [initialValue] = useState(value);
  const handleChange = (event, newValue) => {
    setValue(parseFloat(newValue));
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <Typography>Typical-p</Typography>
        <Slider
          {...rest}
          aria-label='Typical-p'
          defaultValue={initialValue}
          valueLabelDisplay='auto'
          aria-describedby='typical-p-helper-text'
          onChange={handleChange}
          step={0.01}
          min={0}
          max={1}
        />

        <FormHelperText id='typical-p-helper-text'>
          Local typicality measures how similar the conditional probability of
          predicting a target token next is to the expected conditional
          probability of predicting a random token next, given the partial text
          already generated. If set to less than 1, the smallest set of the most
          locally typical tokens with probabilities that add up to typical_p or
          higher are kept for generation.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
