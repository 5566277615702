import React from 'react';

import {
  useTheme,
  useScrollTrigger,
  AppBar,
  Box,
  Toolbar,
  Typography,
} from '@mui/material';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 15,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
}

function Header({ colorInvert = false, window }) {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <>
      <ElevationScroll window={window}>
        <AppBar
          position={'sticky'}
          sx={{
            top: 0,
            display: 'flex',
          }}
        >
          <Toolbar sx={{ backgroundColor: theme.palette.secondary.main }}>
            <Box
              component='a'
              href='/'
              title='QMS PLM Documentation Chat'
              width={{ xs: 100, md: 120 }}
              sx={{ mr: 2, display: 'flex' }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={1}
              >
                <Box
                  component={'img'}
                  src={
                    mode === 'light' && !colorInvert ? '/logo.svg' : '/logo.svg'
                  }
                  height={1}
                  width={1}
                />
              </Box>
            </Box>
            <Box sx={{ mr: 2, display: 'flex' }}>
              <Typography variant='h5' color={theme.palette.common.white}>
                QMS PLM Documentation Chat
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}

export default Header;
