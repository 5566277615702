import { AutoTokenizer, env } from '@xenova/transformers';
env.allowLocalModels = false;

export const trimTokens = async (model, text, max_length) => {
  const tokenizer = await AutoTokenizer.from_pretrained(model);
  if (text) {
    tokenizer.encode(text, undefined, { add_special_tokens: false });

    return tokenizer.decode(
      tokenizer
        .encode(text, undefined, { add_special_tokens: false })
        .slice(0, max_length)
    );
  } else {
    return '';
  }
};

export const getLength = async (model, text) => {
  const tokenizer = await AutoTokenizer.from_pretrained(model);
  if (!text) {
    return 0;
  }
  return tokenizer.encode(text, undefined, { add_special_tokens: false })
    .length;
};
