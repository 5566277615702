import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
  Alert,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import PasswordField from './PasswordField';
import { useConfigurationDispatch } from '../../providers/ConfigurationContext';
import { SET_AUTH_KEY } from '../../providers/actionTypes';

function PasswordDialog({ open, handleClose, error }) {
  const dispatch = useConfigurationDispatch();
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    dispatch({ type: SET_AUTH_KEY, payload: password });
    handleClose();
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs>
            Authorization Required
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {error ? (
              <Grid item xs={12} md={12} lg={12} sx={{ minWidth: '400px' }}>
                <Alert severity='error'>{error}</Alert>
              </Grid>
            ) : undefined}
            <Grid item xs={12} md={12} lg={12} sx={{ minWidth: '400px' }}>
              <PasswordField value={password} setValue={setPassword} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordDialog;
