import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#C924D2',
      light: '#C924D2',
      dark: '#C924D2',
      contrastText: '#fff',
    },
    secondary: {
      light: '#130201',
      main: '#130201',
      dark: '#130201',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

export default theme;
