import React, { useState } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Slider,
  Typography,
} from '@mui/material';

export default function TopPField({ value, setValue, ...rest }) {
  const [initialValue] = useState(value);
  const handleChange = (event, newValue) => {
    setValue(parseFloat(newValue));
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <Typography>Top-p</Typography>
        <Slider
          {...rest}
          aria-label='Top-p'
          defaultValue={initialValue}
          valueLabelDisplay='auto'
          aria-describedby='top-p-helper-text'
          onChange={handleChange}
          step={0.01}
          min={0}
          max={1}
        />

        <FormHelperText id='top-p-helper-text'>
          If set to less than 1, only the smallest set of most probable tokens
          with probabilities that add up to top_p or higher are kept for
          generation.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
