import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PasswordField({ value, setValue }) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    // if (newValue === '') {
    //   setError('Address cannot be blank');
    // } else {
    //   setError(false);
    // }
  };

  return (
    <Box>
      <FormControl fullWidth variant='standard'>
        <InputLabel htmlFor='password'>Password</InputLabel>
        <Input
          id='password'
          aria-describedby='password-helper-text'
          onChange={handleChange}
          value={value}
          variant='standard'
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
        />

        <FormHelperText id='password-helper-text'>
          Enter your application password
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
