import React, { useEffect, useState } from 'react';
import {
  Typography,
  Stack,
  Divider,
  Fab,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PromptField from './PromptField';
import { useConfiguration } from '../../../../providers/ConfigurationContext';
import ChatDialog from './ChatDialog';
import buildPrompt from './buildPrompt';
import searchDocs from './searchDocs';
import SourceDocument from './SourceDocument';
import { addChatItem, getChatItems } from '../../../../actions/chat';
import { getLength } from '../../../../util/tokenizer';
import LoadingMask from '../../../shared/LoadingMask';
import GenerationParameters from '../GenerationParameters';
import ChatEditDialog from './ChatEditDialog';
import ChatDeleteDialog from './ChatDeleteDialog';

function ChatBox({ chat, updateChat, deleteChat }) {
  const configuration = useConfiguration();
  const [prompt, setPrompt] = useState('');
  const [prompt_error, setPromptError] = useState(false);
  const [chat_history, setChatHistory] = useState(null);
  const [source_docs, setSourceDocs] = useState([]);
  const [generation, setGeneration] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (!chat_history) {
      getChatItems(chat.entity_id)
        .then((data) => {
          setChatHistory(data);
        })
        .catch((error) => console.log(error));
    }
  }, [chat?.entity_id, chat_history]);

  const addToChatHistory = async (chat_item) => {
    addChatItem(chat.entity_id, {
      ...chat_item,
      order: chat_history.length,
    }).then((resp) => {
      setChatHistory((prevHistory) => {
        return [...prevHistory, resp];
      });
    });
  };

  const handlePromptSubmit = () => {
    addToChatHistory({
      role: 'user',
      content: prompt,
    })
      .then(() => {
        setSourceDocs([]);
        generateResponse(prompt, chat_history)
          .then((response) => {
            addToChatHistory({
              role: 'assistant',
              content: response.generated_text.trim(),
              sources: JSON.stringify(response.sources),
              finish_reason: response.details.finish_reason,
              input_tokens: response.input_tokens,
              generated_tokens: response.details.generated_tokens,
              seed: response.details.seed,
              temperature: configuration?.generation?.temperature,
            })
              .then(() => setGeneration(null))
              .catch((error) => {
                console.log(error);
                setGeneration(null);
              });
          })
          .catch((error) => {
            console.log(error);
            setGeneration(null);
          });
        setPrompt('');
        setPromptError(false);
      })
      .catch((error) => {
        console.log(error);
        setGeneration(null);
      });
  };

  const generateResponse = async (prompt, chat_history) => {
    setGeneration('');
    const docs = await searchDocs(
      configuration.endpoint.vdb_endpoint,
      configuration.endpoint.vdb_key,
      prompt,
      configuration.generation.num_docs
    );

    const full_prompt = await buildPrompt(
      configuration.model,
      chat_history,
      prompt,
      docs
    );

    const input_tokens = await getLength(
      configuration.model.tokenizer,
      full_prompt
    );

    const init = {
      // signal: AbortSignal.timeout(10000),  // this was causing timeouts with long generations
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        inputs: full_prompt,
        parameters: {
          best_of: undefined,
          details: true,
          decoder_input_details: false,
          do_sample: true,
          max_new_tokens: configuration?.generation?.max_new_tokens,
          // repetition_penalty: undefined,
          return_full_text: false,
          // seed: data.seed,
          // stop: data.stop,
          temperature: configuration?.generation?.temperature,
          // top_k: configuration?.generation?.top_k,
          top_p: configuration?.generation?.top_p,
          truncate: undefined,
          typical_p: configuration?.generation?.typical_p,
          watermark: false,
        },
        stream: true,
      }),
    };
    const response = await fetch(
      `${configuration.endpoint.llm_endpoint}/generate_stream`,
      init
    );

    const reader = response.body.getReader();
    let line = '',
      final_result;

    try {
      while (true) {
        const { done, value } = await reader.read();
        line += new TextDecoder().decode(value);
        if (line.endsWith('\n')) {
          const lines = line
            .trim()
            .split('\n')
            .filter((line) => line);
          for (let i = 0; i < lines.length; i++) {
            const result = JSON.parse(lines[i].substring(5).trim());
            if (result.generated_text) {
              final_result = { ...result, input_tokens, sources: docs };
            } else {
              setGeneration((prevGeneration) => {
                return prevGeneration + result.token.text;
              });
            }
          }
          line = '';
        }
        if (done) {
          setSourceDocs(docs);
          return final_result;
        }
      }
    } catch (error) {
      setSourceDocs(docs);
      throw error;
    }
  };
  if (!chat_history) {
    return <LoadingMask />;
  }

  return (
    <>
      <Grid container spacing={1} style={{ padding: '15px' }}>
        <Grid lg={12} md={12} xs={12}>
          <Stack direction='row' spacing={1} alignItem={'center'}>
            <Typography variant='h5'>{chat.name}</Typography>
            <IconButton onClick={() => setEditOpen(true)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <ChatDialog
            chat_history={chat_history || []}
            generation={generation}
          />
        </Grid>
        {source_docs.length ? (
          <Box
            sx={{
              margin: '10px',
            }}
          >
            <Grid lg={12} md={12} xs={12}>
              <Divider />
            </Grid>{' '}
            <Grid lg={12} md={12} xs={12}>
              <Typography variant='h6'>Sources</Typography>{' '}
            </Grid>
            <Grid container spacing={0} lg={12} md={12} xs={12}>
              {source_docs.map((doc) => {
                return (
                  <Grid lg={12} md={12} xs={12}>
                    <SourceDocument doc={doc} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : undefined}
        <Grid lg={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid container lg={12} md={12} xs={12}>
          <Grid xs={11} lg={11} md={11}>
            <PromptField
              value={prompt}
              error={prompt_error}
              setValue={setPrompt}
              setError={setPromptError}
              onSubmit={handlePromptSubmit}
              disabled={generation !== null}
            />
          </Grid>
          <Grid xs={1} lg={1} md={1} align='right'>
            <Fab
              color='primary'
              aria-label='send'
              onClick={handlePromptSubmit}
              size='small'
              disabled={generation !== null}
            >
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
        <Grid lg={12} md={12} xs={12} align='center'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              setChatHistory([]);
              setSourceDocs([]);
              setGeneration(null);
            }}
          >
            Clear Chat History
          </Button>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <GenerationParameters />
        </Grid>
      </Grid>
      <ChatEditDialog
        key={`edit_${chat.updated_at}`}
        chat={chat}
        open={editOpen}
        updateChat={updateChat}
        handleClose={() => setEditOpen(false)}
      />
      <ChatDeleteDialog
        key={`delete_${chat.updated_at}`}
        chat={chat}
        open={deleteOpen}
        deleteChat={deleteChat}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default ChatBox;
