import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header';
import { ConfigurationProvider } from '../providers/ConfigurationContext';
import { UserProvider } from '../providers/UserContext';

const Layout = () => {
  return (
    <UserProvider>
      <ConfigurationProvider>
        <Header />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Outlet />
          <ScrollRestoration />
        </Box>
      </ConfigurationProvider>
    </UserProvider>
  );
};

export default Layout;
