import { Container, Box, Backdrop, CircularProgress } from '@mui/material';

const LoadingMask = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Backdrop open={true}>
          <Box
            sx={{ display: 'flex' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress color='inherit' size={50} />
          </Box>
        </Backdrop>
      </Container>
    </Box>
  );
};
export default LoadingMask;
