import axios from 'axios';

const api = axios.create({
  baseURL: `https://qms-llm-api.setec.ai/v1`,
});

api.interceptors.request.use((config) => {
  if (config.url !== '/auth') {
    // add token to request headers
    // const token = document.cookie
    //   .split('; ')
    //   .find((row) => row.startsWith('accqsure_jwt='))
    //   ?.split('=')[1];
    const token = localStorage.getItem('accqsure_jwt');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  return config;
});

export default api;
